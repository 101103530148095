.successMessage {
  white-space: pre-line;
  line-height: 1.1;
}

.successMessage .ant-message-custom-content {
  display: flex;
}

.ant-message-custom-content span {
  text-align: left;
}
